import React from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { useStaticQuery, graphql, Link } from "gatsby";
import Alert from "../alert";

import axios from "axios";
import * as Yup from "yup";
import formErrors from "../Global/formErrors";
import { Listbox } from "@headlessui/react";


const SupplierForm = () => {
  const submitForm = (values) => {
    axios({
      headers: {
        "Content-Type": "application/json",
      },
      method: "post",
      url: "https://hook.integromat.com/ksz8mt48g05yy42y9d8dbkefrovrlsa5",
      data: JSON.stringify(values),
    }) 
      .then((r) => {
        console.log("Thanks!", values);
      })
      .catch((r) => {
        console.log("Failed", values);
      });
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("This Field is Required"),
    lastName: Yup.string().required("This Field is Required"),
    eMail: Yup.string()
      .email("Please give your E-Mail")
      .required("Please fill your E-Mail Address"),
    mobileNumber: Yup.number()
      .positive("Please Enter your Valid Phone Number")
      .min(10, "You missed out a Few Characters")
      .required("Please enter your Phone Number"),
    companyIndustry: Yup.string().required("This Field is Required"),
    companyName: Yup.string().required("This Field is Required"),
    mainMessage: Yup.string().required("This Field is Required"),
  });
  return (
    <div className="contact-options-grid">
      <div className="bg-gray-900 p-2"></div>
      <div className="bg-gray-900 p-2"></div>
      <div className="bg-gray-900 p-2"></div>
      <div className="bg-gray-900 p-2"></div>
      <div className="bg-gray-900 col-span-1 px-3 py-5">
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            eMail: "",
            mobileNumber: "",
            companyName: "",
            companyIndustry: "",
            mainMessage: "",
          }}
          onSubmit={(values, actions) => {
            submitForm(values);
            console.log(values);
            actions.setSubmitting(false);
            actions.resetForm();
          }}
          validationSchema={validationSchema}
        >
          <Form className="form-grid">
          <ul className="flex flex-col pb-8 space-y-3 col-span-2 border-b border-dashed border-gray-700">
                <li className="text-3xl font-medium sm:text-4xl text-blue-500">
                  Partner with Kabira Mobility
                </li>
                <li className="text-base text-gray-400 font-light">
                  Fill out this Form and our Supply Chain Team will get back with you shortly
                </li>
              </ul>
            <div className="form-field-container">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <Field
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First Name"
                className="form-input-field"
              />
              <ErrorMessage name="firstName" component={formErrors} />
            </div>
            <div className="form-field-container">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <Field
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                className="form-input-field"
              />
              <ErrorMessage name="lastName" component={formErrors} />
            </div>
            <div className="form-field-container">
              <label htmlFor="eMail" className="form-label">
                E-Mail Address
              </label>
              <Field
                type="text"
                id="eMail"
                name="eMail"
                placeholder="E-Mail Address"
                className="form-input-field"
              />
              <ErrorMessage name="eMail" component={formErrors} />
            </div>
            <div className="form-field-container">
              <label htmlFor="mobileNumber" className="form-label">
                Mobile Number
              </label>
              <ul className="flex flex-row">
                <li className="flex flex-col items-center justify-center px-3 rounded-l bg-gray-800 shadow border-gray-700 border border-r-0 text-gray-400">
                  +91
                </li>
                <li className="flex-1">
                  <Field
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    placeholder="91234567890"
                    className="form-input-field rounded-r rounded-l-none"
                  />
                </li>
              </ul>
              <ErrorMessage name="mobileNumber" component={formErrors} />
            </div>
            <div className="form-field-container">
              <label htmlFor="companyName" className="form-label">
                Company Name
              </label>
              <Field
                type="text"
                id="companyName"
                name="companyName"
                placeholder="Company Name"
                className="form-input-field"
              />
              <ErrorMessage name="companyName" component={formErrors} />
            </div>
            <div className="form-field-container">
              <label htmlFor="companyIndustry" className="form-label">
                Industry
              </label>
              
              <Field
                as="input"
                type="input"
                id="companyIndustry"
                name="companyIndustry"
                placeholder="Sheet Metal"
                className="form-input-field">
                </Field>
              <ErrorMessage name="companyIndustry" component={formErrors} />
            </div>
            <div className="form-field-container col-span-2">
              <label htmlFor="mainMessage" className="form-label">
                Message
              </label>
              <Field
                type="textarea"
                as="textarea"
                id="mainMessage"
                name="mainMessage"
                placeholder="Type your Message here"
                className="form-input-field h-24"
              />
              <ErrorMessage name="mainMessage" component={formErrors} />
            </div>
            <div className="form-field-container col-span-2 flex flex-row justify-end">
              <button type="submit" className="contact-form-button border-r-0">
                Submit
              </button>
            </div>
          </Form>
        </Formik>
      </div>
      <div className="bg-gray-900 p-2"></div>
    </div>
  );
};

export default SupplierForm;
