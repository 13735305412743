import React from "react";

const SupplierHeader = () => {
  return (
    <>
      <main className="contact-options-grid mt-16 ">
        <div className="bg-gray-900 p-2"></div>
        <div className="flex flex-col space-y-3 md:space-y-4 bg-gray-900 p-8 md:p-12">
          <h1 className="text-2xl text-blue-500 font-semibold">Suppliers</h1>

          <h2 className="text-3xl text-white">Suppliers & Vendors</h2>
          <p className="text-base text-gray-400 md:pr-40">
            Kabira Mobility is looking to Partner with Suppliers interested in
            developing the Future of Mobility, Fill out the Form Below to get in
            touch with us
          </p>
        </div>
        <div className="bg-gray-900 p-2"></div>
      </main>
    </>
  );
};

export default SupplierHeader;
