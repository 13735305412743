import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const SupplierPartner = () => {
  return (
    <>
      <div className="contact-options-grid">
        <div className="bg-gray-900 p-2"></div>
        <div className="bg-gray-900 p-2"></div>
        <div className="bg-gray-900 p-2"></div>
        <div className="bg-gray-900 p-2"></div>
        <ul className="flex flex-col space-y-8 bg-gray-900 p-4 md:p-8 lg:p-12">
          <u className = "flex-col flex space-y-3">
              <li>
                <h3 className="text-3xl leading-normal text-gray-300 font-medium md:w-[480px]">
                  Partnered with the Leading Automotive Brands
                </h3>
              </li>
              <li>
                <p className="text-base leading-relaxed text-gray-400 font-light md:pr-32 lg:pr-56">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui
                  adipiscing accumsan, aliquam rutrum posuere amet egestas
                  malesuada. Quam convallis tristique integer sollicitudin. Faucibus
                  eget feugiat elementum est. Quam eu nibh id volutpat amet, in
                  accumsan ipsum, potenti. Egestas sodales vel diam, a pulvinar
                  vestibulum ipsum, egestas.
                </p>
              </li>
          </u>
          <div className="bg-gray-700 grid grid-cols-2 md:grid-cols-3 gap-[1px] border-t-[1px] border-b-[1px] border-gray-700 -mx-4 md:-mx-12">
            <div className=" bg-gray-900 p-3 md:p-8">
              <ul className="flex flex-col space-y-2 justify-center items-center">
                <li>
                  <StaticImage src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638835762/Landing%20Page/Supplier_Logo_icpwig.png" />
                </li>
              </ul>
            </div>
            <div className=" bg-gray-900 p-3 md:p-8">
              <ul className="flex flex-col space-y-2 justify-center items-center">
                <li>
                  <StaticImage src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638835878/Landing%20Page/DeltaLogo_ectrmy.png" />
                </li>
              </ul>
            </div>
            <div className=" bg-gray-900 p-3 md:p-8">
              <ul className="flex flex-col space-y-2 justify-center items-center">
                <li>
                  <StaticImage src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638836003/Landing%20Page/NapinoLogo_cqcbrt.png" />
                </li>
              </ul>
            </div>
            <div className=" bg-gray-900 p-3 md:p-8">
              <ul className="flex flex-col space-y-2 justify-center items-center">
                <li>
                  <StaticImage src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638836241/Landing%20Page/CeatLogo_klr8kz.png" />
                </li>
              </ul>
            </div>
            <div className=" bg-gray-900 p-3 md:p-8">
              <ul className="flex flex-col space-y-2 justify-center items-center">
                <li>
                  <StaticImage src="https://res.cloudinary.com/kabira-mobility/image/upload/v1638836122/Landing%20Page/BoschLogo_jdy4jy.png" />
                </li>
              </ul>
            </div>
            <div className = "bg-gray-900"></div>
           
          </div>
        </ul>
        <div className="bg-gray-900 p-2"></div>
      </div>
    </>
  );
};

export default SupplierPartner;
