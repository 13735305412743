import React from "react";
import Layout from "../components/Global/layout";
import { Formik, Form } from "formik";
import SupplierForm from "../components/Supplier/supplierForm";
import SupplierHeader from "../components/Supplier/supplierHeader";
import SupplierPartner from "../components/Supplier/supplierPartner";
import SEO from "../components/seo/SEO";

const Supplier = () => {
  return (
    <>
<SEO />
      <Layout>
        <SupplierHeader />
        <SupplierForm />
        <SupplierPartner />
      </Layout>
    </>
  );
};

export default Supplier;
